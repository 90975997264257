<template>
    <div class="site-description">
        <div class="site-description__picture" :style="site.picture ? `background-image: url(${imageSrc(site.picture)})` : ''">
            <div class="site-description__gradient"></div>
            <div class="site-description__title">
                <span>{{ site.title }}</span>
                <div class="site-description__info" @click="showDetails = true">
                    <v-icon>mdi-information-outline</v-icon>
                </div>
            </div>
            <div class="site-description__close" v-if="!isActive">Откроется {{openAt}}</div>
        </div>

        <BasePopup class="site-description__popup" v-model="showDetails" @close="showDetails = false">
            <div class="site-description__popup__title">
                <span>{{ site.title }}</span>
            </div>
            <div class="site-description__popup__description" v-if="site.description">
                <span v-html="htmlFormatted(site.description)"></span>
            </div>
            <div class="site-description__popup__description" v-if="site.work_time">
                <b>Режим работы:</b><br>
                <span v-html="htmlFormatted(site.work_time)"></span>
            </div>
            <div class="site-description__popup__description" v-if="site.phone">
                <b>Телефон:</b><br><a :href="`tel:${site.phone}`">{{ site.phone }}</a>
            </div>
            <div class="site-description__popup__description" v-if="site.site">
                <b>Сайт:</b><br><a :href="site.site" target="_blank">{{ site.site }}</a>
            </div>
            <div class="site-description__popup__description" v-if="site.address">
                <b>Адрес:</b><br>
                <span v-html="htmlFormatted(site.address)"></span>
            </div>
            <div class="site-description__popup__description" v-if="site.entities && site.entities.length">
                <b>Зона доставки:</b><br>
                <DeliveryZones
                    :entities="site.entities" />
            </div>
        </BasePopup>
    </div>
</template>

<script>
    import { imageSrc, htmlFormatted } from '../../helpers';
    import helpersEntity from '../../helpers/site';

    import BasePopup from '../common/BasePopup';
    import DeliveryZones from './DeliveryZones';
    
    import { ENTITY_DELIVERY_TYPE } from '@/vars';

    export default {
        name: 'EntityCard',
        components: {
            BasePopup,
            DeliveryZones,
        },
        props: {
            site: {
                type: Object,
                default: () => {}
            }
        },
        data: () => ({
            imageSrc,
            htmlFormatted,
            mapZoom: 12,
            showDetails: false,
        }),
        computed: {
            isActive() {
                return helpersEntity.isActive(this.site);
            },
            openAt() {
                return helpersEntity.openAt(this.site);
            },
            deliveryZones() {
                let deliveries = [];
                if(this.site && this.site.deliveries) {
                    deliveries = this.site.deliveries.filter(item => item.type === ENTITY_DELIVERY_TYPE.DELIVERY_ZONE);
                }
                return deliveries;
            },
        }
    }
</script>

<style lang="scss">
.site-description {
    width: 100%;
    height: 360px;
    overflow: hidden;
    position: relative;
    border-radius: 36px;
    z-index: 0;

    @media screen and (max-width: 460px) {
        height: 260px;
    }

    &__picture {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 40px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media screen and (max-width: 460px) {
            padding: 40px 20px;
        }
    }

    &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.66;
        background: linear-gradient(225deg, rgba(26, 0, 0, 0) 35.77%, #1A0000 90.7%);
        border-radius: 36px;
        z-index: 1;
    }
    
    &__title {
        position: relative;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        font-size: 48px;
        line-height: 50px;
        letter-spacing: -0.03em;
        z-index: 2;

        > span {
            display: -webkit-box;
            overflow: hidden;
            position: relative;
            word-break: break-word;
            text-shadow: 0px 6px 12px rgb(13 50 62 / 15%);
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }

    &__description {
        padding: 10px 0;
    }

    &__info {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        min-width: 48px;
        height: 48px;
        border-radius: 16px;
        margin-left: 20px;
        background: #FFFFFF;
        cursor: pointer;

        .v-icon, 
        .v-icon.v-icon {
            font-size: 30px;
        }
    }

    &__close {
        position: absolute;
        top: 40px;
        right: 40px;
        padding: 14px 20px;
        font-size: 16px;
        line-height: 20px;
        border-radius: 16px;
        background: #FFFFFF;
        z-index: 2;
    }

    &__popup {
        &__title {
            padding: 14px 20px 14px 0;
            font-size: 17px;
            line-height: 24px;
            font-weight: 700;
        }

        &__description {
            padding: 10px 0;
        }
        
        &__map {
            width: 100%;
            height: 400px;

            &__wrapper {
                width: 100%;
                height: 400px;
                border-radius: 16px;
                overflow: hidden;
            }
        }
    }
}
</style>