<template>
    <div class="product-card" @click="$emit('click')">
        <div class="product-card__picture" :style="product.picture ? `background-image: url(${imageSrc(product.picture)})` : ''">
            <div class="product-card__discount" v-if="discountHelpers.typePercent(product.discount)">
                -{{ product.discount.percent }}%
            </div>
        </div>
        <div class="product-card__content">
            <div class="product-card__price">
                <div class="product-card__price__discount" v-if="discount_price !== product.price">
                    <div>{{ discount_price ? priceFormated(discount_price) : 'Бесплатно' }}</div>
                    <div class="product-card__price__discount__before">{{ product.price ? priceFormated(product.price) : 'Бесплатно' }}</div>
                </div>
                <template v-else>
                    {{ product.price ? priceFormated(product.price) : 'Бесплатно' }}
                </template>
            </div>
            <div class="product-card__title">{{ product.title }}</div>
            <div class="product-card__weight">{{ product.weight ? weightFormated(product.weight) : '&nbsp;' }}</div>
            <div class="product-card__buttons" v-if="!hideButtons">
                <BaseCounter
                    v-if="cartQuantity > 0"
                    :value="cartQuantity"
                    :min-value="0"
                    @changed="setCartProductQuantity"
                />
                <BaseButton v-else @click.stop="addCartProduct">В корзину</BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated, weightFormated } from '../../helpers';
    import discountHelpers from '../../helpers/discount';

    import BaseButton from '../common/BaseButton';
    import BaseCounter from '../common/BaseCounter';

    export default {
        name: 'ProductCard',
        components: {
            BaseButton,
            BaseCounter,
        },
        props: {
            product: {
                type: Object,
                default: () => {}
            },
            hideButtons: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            discountHelpers,
            imageSrc,
            priceFormated,
            weightFormated,
        }),
        computed: {
            ...mapState('orders', {
                cart: state => state.cart
            }),
            cartQuantity() {
                return this.cart.products.reduce((q, item) => {
                    if(this.product.id === item.product.id) {
                        q += item.quantity;
                    }
                    return q;
                }, 0);
            },
            discount_price() {
                return discountHelpers.getPrice(this.product);
            },
        },
        methods: {
            setCartProductQuantity(quantity) {
                let productIndex = 0, fullQuantity = 0, productQuantity = 0;
                for(const index in this.cart.products) {
                    if(this.cart.products[index].product.id === this.product.id) {
                        fullQuantity += this.cart.products[index].quantity;
                        productIndex = +index;
                        productQuantity = this.cart.products[index].quantity;
                    }
                }
                quantity = quantity - fullQuantity + productQuantity;
                
                if(quantity === 0) {
                    store.dispatch('orders/removeCartProduct', productIndex);
                } else {   
                    store.dispatch('orders/setCartProductQuantity', { quantity, index: productIndex });
                }
            },
            addCartProduct() {
                if(this.product.groups && this.product.groups.length > 0) {
                    this.$emit('click');
                } else {
                    this.$emit('updateSite');
                    store.dispatch('orders/addCartProduct', { 
                        product: {
                            ...this.product,
                            quantity: 1,
                            unique_code: `${ this.product.id }`
                        }
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
.product-card {
    display: flex;
    padding: 12px;
    position: relative;
    background: #FFFFFF;
    transition: .2s ease box-shadow;
    border-radius: 24px;
    flex-direction: column;

    &__picture {
        position: relative;
        width: 100%;
        height: 168px;
        overflow: hidden;
        border-radius: 18px;
        background-image: url('../../assets/empty-img.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__discount {
        position: absolute;
        bottom: 10px;
        left: 10px;
        background: #fa6a3c;
        color: #ffffff;
        transform: rotate(-5deg);
        padding: 0 10px;
        border-radius: 10px;
    }

    &__content {
        padding: 0 4px;
        margin-top: 14px;
    }
    
    &__price {
        width: 100%;
        display: inline-block;
        position: relative;
        font-weight: 500;
        white-space: nowrap;
        font-size: 20px;
        line-height: 27px;

        &__discount {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            color: #fa6a3c;

            &__before {
                width: 100%;
                font-size: 16px;
                line-height: 23px;
                color: #9E9B98;
                text-decoration: line-through;
                padding: 0 5px;
            }
        }
    }
    
    &__title {
        height: 38px;
        display: -webkit-box;
        overflow: hidden;
        font-size: 16px;
        margin-top: 4px;
        line-height: 19px;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
    
    &__weight {
        color: #9E9B98;
        margin: 5px 0;
        font-size: 16px;
        line-height: 19px;
    }

    &__buttons {

    }
}
</style>