<template>
    <BasePopup class="product-detail" v-bind="$attrs" v-on="$listeners">
        <div class="product-detail__wrap">
            <div class="product-detail__picture" :style="product.picture ? `background-image: url(${imageSrc(product.picture)})` : ''">
                <div class="product-detail__discount" v-if="discountHelpers.typePercent(product.discount)">
                    -{{ product.discount.percent }}%
                </div>
            </div>
            <div class="product-detail__content">
                <div class="product-detail__title">{{ product.title }}</div>
                <div class="product-detail__content__wrap">
                    <div class="product-detail__weight">
                        <template v-if="product.weight">{{ weightFormated(product.weight) }}</template>
                    </div>
                    <div class="product-detail__price">
                        <div class="product-detail__price__discount" v-if="discountPrice(product.price) !== product.price">
                            <div>{{ discountPrice(product.price) ? priceFormated(discountPrice(product.price)) : 'Бесплатно' }}</div>
                            <div class="product-detail__price__discount__before">{{ product.price ? priceFormated(product.price) : 'Бесплатно' }}</div>
                        </div>
                        <template v-else>
                            {{ product.price ? priceFormated(product.price) : 'Бесплатно' }}
                        </template>
                    </div>
                </div>
                <div class="product-detail__description" v-if="product.composition" v-html="htmlFormatted(product.composition)"></div>
            </div>
        </div>
        <div class="product-detail__description" v-if="product.description" v-html="htmlFormatted(product.description)"/>
        <div class="product-detail__groups" v-if="product.groups && product.groups.length">
            <template v-for="group in product.groups">
                <div class="product-detail__groups__item" :key="`product-group-${group.id}`">
                    <div class="product-detail__groups__title">{{ group.title }}</div>
                    <div class="product-detail__options" v-if="group.options && group.options.length">
                        <template v-if="group.multiple">
                            <template v-for="option in group.options">
                                <BaseCheckbox 
                                    :key="`product-group-option-${option.id}`"
                                    v-model="optionsCheckbox"
                                    :value="option.id">
                                    <template v-slot:label="">
                                        <span>{{ option.title }}</span><span><template v-if="option.price">+ </template>{{ priceFormated(discountPrice(option.price)) }}</span>
                                    </template>
                                </BaseCheckbox>
                            </template>
                        </template>
                        <template v-else>
                            <v-radio-group
                                v-model="optionsRadio[group.id]"
                                hide-details>
                                <template v-for="option in group.options">
                                    <BaseRadio 
                                        :key="`product-group-option-${option.id}`"
                                        :value="option.id">
                                        <template v-slot:label="">
                                            <span>{{ option.title }}</span><span><template v-if="option.price">+ </template>{{ priceFormated(discountPrice(option.price)) }}</span>
                                        </template>
                                    </BaseRadio>
                                </template>
                            </v-radio-group>
                        </template>
                    </div>
                </div>
            </template>
        </div>
        <div class="product-detail__buttons" v-if="!hideButtons">
            <BaseButton @click.stop="addCartProduct">В корзину</BaseButton>
        </div>
    </BasePopup>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated, weightFormated, htmlFormatted } from '../../helpers';
    import discountHelpers from '../../helpers/discount';

    import BaseButton from '../common/BaseButton';
    import BasePopup from '../common/BasePopup';
    import BaseCheckbox from '../common/BaseCheckbox';
    import BaseRadio from '../common/BaseRadio';

    export default {
        name: 'EntityCard',
        components: {
            BaseButton,
            BasePopup,
            BaseCheckbox,
            BaseRadio,
        },
        props: {
            product: {
                type: Object,
                default: () => {}
            },
            hideButtons: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            imageSrc,
            priceFormated,
            weightFormated,
            htmlFormatted,
            discountHelpers,
            optionsCheckbox: [],
            optionsRadio: {},
        }),
        watch: {
            product: {
                deep: true,
                async handler() {
                    this.optionsRadio = {};
                    for(const group of this.product.groups) {
                        if(!group.multiple && group.options && group.options.length) {
                            this.optionsRadio[group.id] = group.options[0].id;
                        }
                    }
                }
            },
        },
        methods: {
            discountPrice(price) {
                return discountHelpers.getPrice({ price, discount: this.product.discount });
            },
            addCartProduct() {
                const options = [];
                let optionIds = [];
                for(const value of this.optionsCheckbox) {
                    optionIds.push(value);
                }
                for(const key in this.optionsRadio) {
                    optionIds.push(this.optionsRadio[key]);
                }
                optionIds = optionIds.filter((value, index, self) => {
                    return self.indexOf(value) === index;
                });
                optionIds = optionIds.sort((a, b) => (a - b));

                for(const group of this.product.groups) {
                    for(const option of group.options) {
                        if(optionIds.indexOf(option.id) >= 0) {
                            options.push(option);
                        }
                    }
                }
                
                this.$emit('updateSite');
                store.dispatch('orders/addCartProduct', { 
                    product: {
                        ...this.product,
                        quantity: 1,
                        unique_code: `${ this.product.id }-options-${ optionIds.join('-') }`
                    },
                    options
                });
                this.$emit('close');
            }
        }
    }
</script>

<style lang="scss">
.product-detail {
    padding: 12px;
    position: relative;
    background: #FFFFFF;
    transition: .2s ease box-shadow;
    border-radius: 24px;

    &__wrap {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
            
        @media screen and (max-width: 460px) {
            display: block;
        }
    }

    &__picture {
        position: relative;
        width: 48%;
        height: 315px;
        overflow: hidden;
        border-radius: 18px;
        background-image: url('../../assets/empty-img.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
            
        @media screen and (max-width: 460px) {
            width: 100%;
            margin-top: 24px;
        }
    }

    &__discount {
        position: absolute;
        bottom: 10px;
        left: 10px;
        background: #fa6a3c;
        color: #ffffff;
        transform: rotate(-5deg);
        padding: 0 10px;
        border-radius: 10px;
    }

    &__content {
        width: 48%;
        padding: 0 4px;
        margin-top: 14px;
            
        @media screen and (max-width: 460px) {
            width: 100%;
        }

        &__wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__price {
        display: inline-block;
        position: relative;
        font-weight: 500;
        white-space: nowrap;
        font-size: 20px;
        line-height: 27px;

        &__discount {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            color: #fa6a3c;

            &__before {
                width: 100%;
                font-size: 16px;
                line-height: 23px;
                color: #9E9B98;
                text-decoration: line-through;
                padding: 0 5px;
            }
        }
    }
    
    &__title {
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
        overflow: hidden;
    }
    
    &__description {
        font-size: 14px;
        line-height: 17px;
        padding-top: 20px;
        
    }
    
    &__weight {
        color: #9E9B98;
        margin: 5px 0;
        font-size: 16px;
        line-height: 19px;
    }

    &__groups {
        border-bottom: 1px solid #efefef;

        .v-input--radio-group {
            margin: 0;
            padding: 0;
        }

        &__item {
            padding: 10px 0;
            border-top: 1px solid #efefef;
        }
        &__title {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 5px;
            color: var(--primary_color);
        }
    }

    &__buttons {
        padding-top: 10px;
    }
}
</style>