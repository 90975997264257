<template>
    <div :class="`${mainClass} body-background`">
        <div :class="`${mainClass}__title`">Меню</div>
        <div :class="`${mainClass}__items`" ref="categoriesMenuRef">
            <template v-for="category in categories">
                <div 
                    :key="`category-${category.id}`"
                    :ref="`category-${category.id}`"
                    :class="`${mainClass}__item ${activeCategory === category.id ? `${mainClass}__item__active` : ''}`"
                    v-scroll-to="`#category-${category.id}`"
                >{{ category.title }}</div>
            </template>
        </div>
    </div>
</template>

<script>
    import { imageSrc } from '../../helpers';

    export default {
        name: 'CategoriesMenu',
        props: {
            categories: {
                type: Array,
                default: () => []
            },
            activeCategory: {
                type: Number,
                default: 0
            },
            mobile: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            mainClass() {
                return this.mobile ? 'entity__menu__mobile' : 'entity__menu';
            }
        },
        watch: {
            activeCategory: {
                deep: true,
                async handler() {
                    // console.log(this.root.scrolling);
                    if(this.activeCategory > 0) {
                        this.$refs['categoriesMenuRef'].scrollLeft += this.$refs[`category-${this.activeCategory}`][0].getBoundingClientRect().x - 20;
                    }   
                }
            },
        },
        methods: {
            // setCategory(id) {
            //     this.$emit('change', id);
            //     return false;
            // }
        }
    }
</script>

<style lang="scss">
.entity__menu {
    width: 230px;
    min-width: 230px;
    height: calc(100vh - 90px);
    padding: 40px 0 0 0;
    position: sticky;
    top: 90px;
    
    @media screen and (max-width: 900px) {
        display: none;
    }

    &__title {
        padding: 14px 20px 14px 16px;
        font-size: 17px;
        line-height: 24px;
        font-weight: 700;
    }

    &__items {
        height: calc(100% - 52px);
        margin: 0 -10px;
        padding: 0 10px;
        overflow-x: hidden;
        overflow-y: auto; 
        border-top: 1px solid #F5F4F2;
        scroll-behavior: smooth;
    }

    &__item {
        width: 100%;
        min-width: 200px;
        padding: 14px 20px 14px 16px;
        overflow: hidden;
        transition: background .1s;
        font-size: 16px;
        line-height: 20px;
        border-radius: 16px;
        cursor: pointer;

        &__active {
            background: #FFFFFF;
            font-weight: 500;
        }
    }

    &__mobile {
        position: sticky;
        top: 70px;
        display: none;
        
        z-index: 11;

        @media screen and (max-width: 900px) {
            display: block;
        }

        &__items {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            overflow: hidden;
            overflow-x: auto;
            padding: 10px 10px;
            margin: 10px -10px -10px;
            scroll-behavior: smooth;
        }

        &__title {
            display: none;
        }

        &__item {
            padding: 14px 20px;
            transition: background .1s;
            font-size: 16px;
            line-height: 20px;
            border-radius: 16px;
            white-space: nowrap;
            cursor: pointer;

            &__active {
                background: #FFFFFF;
                font-weight: 500;
            }
        }
    }
}
</style>