<template>
    <div class="site-discounts">
        <div class="site-discounts__items">
            <template v-for="discount in discounts">
                <div v-if="discount.picture" :key="`discount-${discount.id}`" class="site-discounts__item">
                    <div class="site-discounts__item__picture" :style="discount.picture ? `background-image: url(${ imageSrc(discount.picture) })` : ''">
                        <div class="site-discounts__item__gradient"></div>
                        <div class="site-discounts__item__title">
                            {{ discount.title }}
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { imageSrc } from '../../helpers';

    export default {
        name: 'Discounts',
        props: {
            discounts: {
                type: Array,
                default: () => []
            },
        },
        data: () => ({
            imageSrc
        }),
        async mounted() {
        }
    }
</script>

<style lang="scss">
    .site-discounts {
        padding: 20px 0 0 0;

        &__items {
            margin-left: -12px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            @media screen and (max-width: 900px) {
                flex-wrap: nowrap;
                overflow: hidden;
                overflow-x: auto;
                padding: 0 10px;
                margin: 0 -10px;
                scroll-behavior: smooth;
            }
        }

        &__item {
            display: flex;
            margin: 0 0 12px 12px;
            padding: 12px;
            position: relative;
            background: #ffffff;
            transition: 0.2s ease box-shadow;
            border-radius: 24px;
            flex-direction: column;

            @media screen and (max-width: 900px) {
                &:first-child {
                    margin-left: 0;
                }
            }

            &__picture {
                position: relative;
                width: calc((100vw - 105px) / 2);
                min-width: 150px;
                max-width: 225px;
                height: calc(((100vw - 105px) / 2) * 2 / 3);
                min-height: 100px;
                max-height: 150px;
                overflow: hidden;
                border-radius: 18px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;

                @media screen and (max-width: 460px) {
                    width: calc((100vw - 80px) / 2);
                    height: calc(((100vw - 80px) / 2) * 2 / 3);
                }
            }

            &__gradient {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.66;
                background: linear-gradient(315deg, rgba(26, 0, 0, 0) 35.77%, #1A0000 90.7%);
                border-radius: 18px;
                z-index: 1;
            }

            &__title {
                position: absolute;
                padding: 12px;
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
                z-index: 2;
            }
        }
    }
</style>